<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col>
        <div class="text-center mb-3">
          <v-avatar size="150">
            <img alt="user" src="/images/Icon.png" />
          </v-avatar>
        </div>
        <v-card class="mx-auto mb-6 text-center pulse" max-width="300" rounded="pill">
          <v-btn class="text-h5" color="yellow" height="60" block large rounded :to="{ name: 'PageMainLogin' }" id="StartGame">
            {{ $t("action.start") }}
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    //
  }),
  computed: mapState({
    settings: (state) => state.settings.data,
    auth: (state) => state.auth.data,
  }),
  mounted() {
    //
  },
  created() {
    //
  },
  methods: {
    //
  },
};
</script>

<style scoped>
.pulse:hover {
  animation: none;
  transform: scale(1.05);
}

.pulse {
  animation: beat 0.8s infinite alternate;
  transform-origin: center;
  margin: 10px;
}

@keyframes beat {
  to {
    transform: scale(1.05);
  }
}
</style>
